<template>
  <div class="wrap">
    <div class="top">
      <div class="banner_wrap">
        <!-- <img src="../assets/img/banner@2x.png" alt=""> -->
        <!-- <span>一元问诊</span> -->
        <img :src="detail.topBannerPic" alt="">
        <!-- <span>{{detail.activityName}}</span> -->
        <!-- <span>医生为您的健康指导</span> -->
      </div>
    </div>
    <div class="title">
      <span>问诊说</span>
    </div>
    <div class="middle">
      <div class="middle_item" v-for="item in currentList" :key="item.orderNum">
        <div class="item_top">
          <img src="../assets/img/banner@2x.png" alt="">
          <div class="item_top_title"><span>{{item.interrogationPeople}}</span></div>
          <van-rate size="20px" readonly color="#ffd21e" void-color="#eee" v-model="item.serviceAttitude" />
        </div>
        <div class="item_under">
          <span>评论：</span>
          <span>{{item.attentionContent}}</span>
        </div>
      </div>
    </div>
    <div class="under">
      <div class="under_left">
        <span>活动价格: </span>
        <span>¥{{detail.activityPrice}}</span>
      </div>
      <div class="under_right">
        <button @click="immediately">立即问诊</button>
      </div>
    </div>
    <v-login :is_login="is_login" @onLoginSuccess="onLoginSuccess" :isCode="false" />
  </div>
</template>
<script>
import vLogin from "@/components/login";
import BASE_URL from "@/request/base";
import { getQueryVariable } from "../utils/util";
export default {
  components: { vLogin },
  data() {
    return {
      value: 3,
      is_login: false,
      currentList: [],
      detail: {}
    }
  },
  created() {
    let loginData = JSON.parse(localStorage.getItem("userData")) || "";
    this.$store.state.visitsType = 4;
    if (loginData) {
      // console.log("getList====>");
      this.is_login = false;
      this.getActiveInfoById()
      this.getListEstimateInfo()
      // this.show = true;
    } else {
      this.is_login = true;
    }
    console.log(this.$route.meta.title)
  },
  mounted() {
    if (navigator.userAgent.toLowerCase().match(/MicroMessenger/i) == "micromessenger") {
      wx.miniProgram.getEnv((res) => {
        if (res.miniprogram) {        //在小程序
        } else {                   //在公众号
          var wxUrl = `${location.href}`;
          let openId = localStorage.getItem("openid") || "";
          if (!openId) {
            if (
              navigator.userAgent.toLowerCase().match(/MicroMessenger/i) ==
              "micromessenger"
            ) {
              if (wxUrl.indexOf("code") > -1) {
                let code = getQueryVariable("code");
                this.$http(
                  "get",
                  "/server/pay/getOfficialAccountOpenid?code=" + code,
                  {},
                  1
                ).then((res) => {
                  if (res.data.code == 200) {
                    this.openid = res.data.data;
                    localStorage.setItem("openid", this.openid);
                  }
                });
              } else {
                const appid = "wx41c11ad329b19f43";
                let http =
                  "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
                  appid +
                  "&redirect_uri=" +
                  wxUrl +
                  "&response_type=code&scope=snsapi_base&state=123#wechat_redirect";
                location.href = http;
              }
            }
          }
        }
      })
    }
  },
  methods: {
    // 立即问诊
    immediately() {
      const message = this.$store.state.message
      const relation = this.$store.state.relation
      this.$store.commit('upDatePrice', this.detail.activityPrice)
      if (message && relation) {
        this.$router.push('/fast_order')
      } else {
        this.$router.push('/choose_patients')
      }

    },
    // 下单（只新增了参数）
    // addOrder() {
    //   const data = {
    //     isActivity: true,
    //     activityId: this.$route.query.id
    //   }
    //   this.$http('post','/server/order/addOrder',data,1).then(res => {
    //     // Toast.success('');
    //      this.$router.push('/fast_order')
    //   })
    //   // this.$router.push('/fast_order')
    // },
    // 门诊说
    getListEstimateInfo() {
      const id = this.$route.query.id
      this.$http('post', `/server/activityInfo/listEstimateInfo/${id}`, {}, 1).then(res => {
        this.currentList = res.data.data;
      })
    },
    // 根据id获取当前活动
    getActiveInfoById() {
      const id = this.$route.query.id
      this.$http('post', `/server/activityInfo/get/${id}`, {}, 1).then(res => {
        this.detail = res.data.data;
        // this.$route.meta.title = res.data.data.activityName || '活动详情';
        document.querySelector("title").innerText = res.data.data.activityName || '活动详情';
      })
    },
    // 登录成功
    onLoginSuccess() {
      this.is_login = false;
      // this.show = true;
      this.getActiveInfoById()
      this.getListEstimateInfo()
    }
  }
}
</script>
<style lang="less" scoped>
.wrap {
  min-height: calc(100% - 0.2rem);
  // padding: 0 0.24rem;
  // padding-top: 0.2rem;
  background: #f3f3f3;
  .under {
    height: 1.18rem;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    height: 1.18rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.26rem 0 0.4rem;
    box-shadow: 0 0 10px #e3e3e3;
    .under_right > button {
      width: 1.96rem;
      height: 0.68rem;
      border-radius: 0.76rem;
      color: #ffffff;
      line-height: 0.48rem;
      font-weight: 500;
      font-size: 0.32rem;
      background: linear-gradient(270deg, #ff9f22 0%, #ff8d3a 100%);
      border: none;
    }
    .under_left {
      span:first-child {
        font-size: 0.28rem;
        color: #333333;
        line-height: 0.42rem;
      }
      span:last-child {
        font-size: 0.32rem;
        font-weight: 500;
        color: #ffa438;
        line-height: 0.48rem;
      }
    }
  }
  .middle {
    padding: 0.24rem;
    padding-bottom: 1.8rem;
    min-height: 7rem;
    // height: calc(100% - 5.08rem);
    overflow: auto;
    .middle_item {
      background-color: #ffffff;
      border-radius: 0.08rem;
      // width: 7.02rem;
      // height: 1.92rem;
      padding: 0.32rem;
      margin-bottom: 0.16rem;
    }
    .item_under {
      font-size: 0.26rem;
      font-weight: 400;
      color: #666666;
      line-height: 0.36rem;
    }
    .item_top {
      margin-bottom: 0.16rem;
      position: relative;
      img {
        width: 0.56rem;
        height: 0.56rem;
        border-radius: 50%;
        vertical-align: middle;
        margin-right: 0.2rem;
      }
      .item_top_title {
        display: inline-block;
        font-size: 0.3rem;
        font-weight: 400;
        color: #333333;
        line-height: 0.42rem;
        vertical-align: middle;
        // margin-right: 3.2rem;
      }
      .van-rate {
        // vertical-align: middle;
        position: absolute;
        top: 0.12rem;
        right: 0.08rem;
        // float: right;
      }
    }
  }
  .top {
    // height: 3.9rem;
    background-color: #ffffff;
    padding: 0.18rem 0.26rem 0.16rem 0.24rem;
    .banner_wrap {
      width: 7rem;
      height: 2.76rem;
      // background-color: orange;
      border: none;
      border-radius: 0.08rem;
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }
      span:nth-child(2) {
        line-height: 0.6rem;
        letter-spacing: 5px;
        text-shadow: 0px 0px 0px #0063db;
        font-size: 0.5rem;
        font-weight: normal;
        color: #ffffff;
        position: absolute;
        top: 0.8rem;
        right: 1.06rem;
      }
      span:nth-child(3) {
        text-shadow: 0px 0px 0px #2e97f2;
        font-size: 0.34rem;
        font-weight: normal;
        position: absolute;
        top: 1.44rem;
        right: 0.38rem;
        color: #ffffff;
        line-height: 0.48rem;
        letter-spacing: 3px;
      }
    }
  }

  .title {
    font-size: 0.32rem;
    line-height: 0.44rem;
    color: #333333;
    font-weight: 500;
    margin-top: 0.25rem;
    padding: 0 0.24rem;
  }
}
</style>
